
export class IpMatchingModel {

  ipAddresses?: string[] = [];
  spokeId?: string = '';
  projectId?: string = '';

  constructor(partial?: Partial<IpMatchingModel>) {
      Object.assign(this, partial);
  }
}
